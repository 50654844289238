
angular
.module('fonderieComponent')
.directive('clearCart', [ 'storeService', function (Store) {

    return {
        restrict: 'E',
        scope : {
        },
        link: function ($scope, $element, $attr) {

            Store.clearCart();

            

        }
    }

}]);