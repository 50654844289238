/**
 * Abstract Object defining a youtube item in the assets manager list. 
 * @name MediaYoutubeItem
 * @ngdoc service
 */
angular.module('fonderieComponent').factory('MediaYoutubeItem', [ '$q', '$http', '$log', function ($q, $http, $log) {


	function MediaYoutubeItem (payload) { 

        var self = this;
        
        self.component = 'youtube';

		self.type = 'video';
        self.media_source = "youtube";

        //Lets assume that the default ratio on youtube is 16:9 ---Too bad.
        self.videoWidth = 16;
        self.videoHeight = 9;


        var getQueryVariable = function(url, variable) {

            var query = url.substring(1);
            var vars = query.split('&');
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split('=');
                if (decodeURIComponent(pair[0]) == variable) {
                    return decodeURIComponent(pair[1]);
                }
            }
        }

        if(typeof payload == 'string') {
            self.id = MediaYoutubeItem.parseId(payload);
        }

        if(typeof payload == 'object') {



            var cc_load_policy = getQueryVariable(payload.url, 'cc_load_policy');

            console.log(cc_load_policy);

            if(cc_load_policy == undefined) {

                self.cc_load_policy = 0;

            } else {

                self.cc_load_policy = cc_load_policy;
            
            }
            
            if(payload.name != undefined) {

                self.title = payload.name;

            }

            if(payload.title != undefined) {

                self.title = payload.title;
                
            }


            if(payload.thumbnails != undefined) {

                if(payload.thumbnails.maxres != undefined) {
                    self.thumbnail = payload.thumbnails.maxres.url;
                } else if(payload.thumbnails.high != undefined) {
                    self.thumbnail = payload.thumbnails.high.url;
                } else if(payload.thumbnails.medium != undefined) {
                    self.thumbnail = payload.thumbnails.medium.url;
                } else if(payload.thumbnails.standard != undefined) {
                    self.thumbnail = payload.thumbnails.standard.url;
                } else {
                    self.thumbnail = payload.thumbnails.default.url;
                }

            }

            if(payload.loop != undefined) {
                self.loop = payload.loop;
            }

            if(payload.autoplay != undefined) {
                self.autoplay = payload.autoplay;
            }

            $.extend(self, payload);

        }

	}


    MediaYoutubeItem.type = MediaYoutubeItem.prototype.type = 'video';


	/**
	 * Static function to validate a payload against this type.
	 * @param {Object} payload The payload of the asset to validate against.
	 * @return {Boolean} Returns a boolean whether the payload matches cirteria or not.
	 */
	MediaYoutubeItem.validate = function (payload) {

		//If no payload was provided stop process.
		if(payload == undefined) {

			return false;
            
		}

        if(payload.media_source != undefined){

            if(payload.media_source == 'youtube') {

                return true;

            } else {

                return false;
            }

        }

		if(typeof payload == 'string') {

			if(payload.indexOf('youtube.com') != -1 || payload.indexOf('youtu.be') != -1){
				return true;
			}
		
		}


		if(typeof payload == 'object' && payload.url != undefined) {

			var url = payload.url; 

			if(url.indexOf('youtube.com') != -1 || url.indexOf('youtu.be') != -1){
				return true;
			}

		}

		return false;

	}

    MediaYoutubeItem.load = function(payload) {

        var query = {};

    	if(payload == undefined) {

    		$log.warning('Unable to load youtube assets');
    		return; 
    	}

        //If paylaod is string we assume its a url.
        if(typeof payload === 'string') {

            var id = MediaYoutubeItem.parseId(payload);

            query.url = payload;
            query.id = id;

        }

    	//If the query id is undefined try to fetch its meta through id.
    	if(payload.url != undefined) {

    		console.log('url at youtube');

    		var id = MediaYoutubeItem.parseId(query.url);

    		query.url = payload.url;
            query.id = id;

        }
        
    	
        var deferred = $q.defer();

        $http({
            method: 'GET',
            url: 'https://www.googleapis.com/youtube/v3/videos',

            params: {

                id: query.id,
                key: 'AIzaSyD5nQBH-6BlDxtedlN6VGRyoaNxxPVxDCk',
                part: 'snippet,contentDetails'

            }

        }).then(function(response) {


            var data = response.data;

            var tempData = data.items[0].snippet;

            tempData.url = query.url;
            tempData.id = query.id;
            
            var contentDetails = data.items[0].contentDetails;

            //Checking if content details is defined
            if(contentDetails != undefined) {

                if(contentDetails.duration != undefined) {

                    //Found parser from https://stackoverflow.com/questions/22148885/converting-youtube-data-api-v3-video-duration-format-to-seconds-in-javascript-no
                    tempData.duration = moment.duration(contentDetails.duration).asMilliseconds()/1000;

                }

            }
            
            if(tempData.name != undefined) {

                tempData.title = tempData.name;

            }

            if(tempData.title != undefined) {

                tempData.title = tempData.title;
                
            }

            if(tempData.thumbnails != undefined) {

                if(tempData.thumbnails.maxres != undefined) {
                    tempData.thumbnail = tempData.thumbnails.maxres.url;
                } else if(tempData.thumbnails.high != undefined) {
                    tempData.thumbnail = tempData.thumbnails.high.url;
                } else if(tempData.thumbnails.medium != undefined) {
                    tempData.thumbnail = tempData.thumbnails.medium.url;
                } else if(tempData.thumbnails.standard != undefined) {
                    tempData.thumbnail = tempData.thumbnails.standard.url;
                } else {
                   tempData.thumbnail = tempData.thumbnails.default.url;
                }

            }


            tempData.width = 16;
            tempData.height = 9;

            deferred.resolve(tempData);

        }).catch(function(response) {


        });

        return deferred.promise;

    }

    MediaYoutubeItem.parseId = function (url) {

    	var regExp = /^.*(youtu\.be\/|vi?\/|u\/\w\/|embed\/|\?vi?=|\&vi?=)([^#\&\?]*).*/;
        var match = url.match(regExp);
        if (match && match[2].length == 11) {
            return match[2];
        } else {
            return 'id not parsed'
        }

    }

    MediaYoutubeItem.getRatio = function(id) {

        var deferred = $q.defer();

        var data = {};


        data.videoWidth = 16;
        data.videoHeight = 9;
        deferred.resolve(data);

        return deferred.promise;

    }


    MediaYoutubeItem.prototype.parseId = MediaYoutubeItem.parseId; 

    MediaYoutubeItem.prototype.load = MediaYoutubeItem.load;

	return MediaYoutubeItem;

}]);