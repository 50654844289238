
angular
.module('fonderieComponent')
.directive('cart', [ 'stripe','storeService', function (stripe, Store) {

    return {
        restrict: 'E',
        templateUrl:"js/partials/cart.html",

        scope : {


        },
        link: function ($scope, $element, $attr) {

          $scope.cartItems = Store.getItems();
          $scope.hasShippingItems = true;

          Store.addEventListener('cartUpdated', function() {

            var cartItems = Store.getItems();
            $scope.cartItems = cartItems;

          });

          $scope.getPrice =  function(item){
            return item.price.unit_amount/100;
          }

          $scope.getQuantity =  function(item){
            return item.quantity;
          }

          $scope.cartIsEmpty = function(){

            return Store.cartIsEmpty();
          }

          $scope.getTotal = function (){
            var total = 0;

            this.cartItems.forEach(function (item) {
      
              total+=item.quantity*item.price.unit_amount/100;
            });

            return total;
          }

          $scope.removeItem = function (item){
            return Store.removeItem(item);
          }

          $scope.closeCart = function() {
            Store.hideCart();
          }

          $scope.checkout = function() {

            if(Store.cartIsEmpty()){
              return;
            }

            Store.checkout$().then(function(response) {

              console.log(response);

            })
          }

          $scope.addItem = function(item) {

            item.quantity +=1;
            item.quantity = Math.max(0, item.quantity);

            Store.updateItem(item);
            
          }

          $scope.subtractItem = function(item) {


            item.quantity -=1;
            item.quantity = Math.max(0, item.quantity);

            Store.updateItem(item);
          }

        }
    }

}]);