
angular
.module('fonderieComponent')
.directive('store', [ 'stripe','storeService', '$compile', '$rootScope', function (stripe, Store, $compile,$rootScope) {

    return {

        restrict: 'E',
        scope : {


        },
        link: function ($scope, $element, $attr) {

        
          $scope.$on('$destroy', function(){

            console.log('destroy');
          })

          Store.addEventListener('showCart', function(e){

            var cartElement = angular.element('#cart');

            if(cartElement.length==0){

              angular.element('#main').addClass('hasCart');

              var newScope =  $rootScope.$new(true);
  
              angular.element('#main').append('<div id="cart" class="cart"><cart></cart></div>');
  
              var cartElement = angular.element('.cart');
  
              $compile(cartElement)(newScope);
            }

          });



          Store.addEventListener('hideCart', function(e){

            var cartElement = angular.element('.cart');

            if(cartElement){
              cartElement.remove();

              angular.element('#main').removeClass('hasCart');

            }

          });

        }
    }

}]);