
angular
   .module('fonderieComponent')
   .directive('framer', [ 'resizer', function (resizer) {

      return {
        restrict: 'E',
        scope : {
            cropx:'@',
            cropy:'@',
            cropwidth:'@',
            cropheight:'@',
            width:'@',
            height:'@',
            layout:'<'

        },
        link: function ($scope, $element, $attr) {

            var image;


            $scope.cropx = Number($scope.cropx);
            $scope.cropy = Number($scope.cropy);

            $scope.cropwidth = Number($scope.cropwidth);
            $scope.cropheight = Number($scope.cropheight);

            $scope.width = Number($scope.width);
            $scope.height = Number($scope.height);



            $scope.$watch('width', function () {

                $scope.width = Number($scope.width);
            });

            $scope.$watch('height', function () {


                $scope.height = Number($scope.height);
            });



            var elementStyle = $element[0].style;

            var parent = $element.parent();

            elementStyle.display = 'block';
            elementStyle.position = 'absolute';
            elementStyle.left = '0px';
            elementStyle.top ='0px';
            elementStyle.width = '100%';
            elementStyle.height = '100%';


            elementStyle.overflow = 'hidden';


            if($scope.layout == undefined) {

                $scope.layout = "fit";
            }

            if($attr.layout != undefined) {

                $scope.layout = $attr.layout;
            }


            // With default options (will use the object-based approach).
            // The object-based approach is deprecated, and will be removed in v2.
            // var erd = elementResizeDetectorMaker();

            // var resized=false;

            /* erd.listenTo($element[0], function(element) {

                    resized = false;
                    resize();



                });
            */

            resizer.addEventListener('resize', function (callback) {

                resize(callback);

            }, $element);

            $scope.$watch('layout', function () {

                resized = false;
                resize();

            });
           
            var imgDom = $element.children();



            imgDom.on('load', function () {

                image = this;

                var scope = $scope;


                if(image.naturalWidth != undefined) {

                    scope.width = Number(image.naturalWidth);
                }

                if(image.naturalHeight != undefined) {

                    scope.height = Number(image.naturalHeight);
                    
                }

                resize();

            });



            var resize =  function (callback) {


                var scope = $scope;
                $element.addClass('fadeOut');
                $element.removeClass('fadeIn');




                requestAnimationFrame(function() {

   

                    if(scope.layout == "normal") {

                        computeNormalLayout();

                    } else if(scope.layout == "fit") {

                        computeFitLayout();

                    }
                    

                    if(typeof callback == 'function'){

                        callback();

                    };


                    window.setTimeout(function () {

                            
                        $element.addClass('fadeIn');
                        $element.removeClass('fadeOut');


                    }, 200);


                });



            }

            $scope.resize = resize;

            resize();



            var computeNormalLayout = function () {

                var scope = $scope;

                if(image == undefined) {
                    
                    imgDom = $element.children();
                    image = imgDom[0];

                }

                var parentW = $element[0].offsetWidth;
                var parentH = $element[0].offsetHeight;

                var elementW, elementH;


                if(image.naturalWidth != undefined) {

                    elementW = Number(image.naturalWidth);
                }

                if(image.naturalHeight != undefined) {

                    elementH = Number(image.naturalHeight);
                    
                }


                if(parentW/parentH < scope.width/scope.height) {


                    elementW = parentW;

                    elementH = elementW*(scope.height/scope.width);


                    image.style.height = elementH +"px";
                    image.style.width = elementW+"px";
                    image.style.position = "absolute";
                    image.style.margin = "auto";
                    image.style.display = "block";
                    image.style.top = "0px";


            


                    image.style.top = parentH/2-elementH/2 + "px";

                    image.style.left = parentW/2-elementW/2+"px";

                } else {



                    elementH = parentH;



                    elementW = elementH*(scope.width/scope.height);

                    image.style.height = elementH +"px";
                    image.style.width = elementW+"px";
                    image.style.position = "absolute";
                    image.style.margin = "auto";
                    image.style.display = "block";
                    image.style.left = parentW/2-elementW/2+"px";
                    image.style.top = parentH/2-elementH/2 + "px";

                }


            }


            var computeFitLayout = function () {

                var scope = $scope;


                if(image == undefined) {
                    
                    imgDom =  $element.children();
                    image = imgDom[0];

                }

                var RCx = Number(scope.cropx) + Number(scope.cropwidth)/2;
                var RCy =  Number(scope.cropy) + Number(scope.cropheight)/2;

                var RCxPct =  RCx/scope.width;
                var RCyPct = RCy/scope.height;

                var imageW = image.naturalWidth;
                var imageH = image.naturalHeight;

                var parentW = $element[0].offsetWidth;
                var parentH = $element[0].offsetHeight;


                var imageRatio = imageH/imageW;

                var parentRatio = parentH/parentW;

            
                image.style.position = "absolute";

                //Width will be 100%;
                if(imageRatio > parentRatio) {

                    var imageWidth = parentW;

                    image.style.width = imageWidth+"px";
                    image.style.height = "auto";

                    var top = RCyPct * image.offsetHeight - parentH/2; 

                    if(top < 0) {

                        top = 0;
                    }

                    if(image.offsetHeight - top < parentH) {

                        top += (image.offsetHeight - top) - parentH;

                    }

                    image.style.top = -top + "px";
                    image.style.left = "0px";

                } else {

                    var imageHeight = parentH;

                    image.style.height = imageHeight + "px";
                    image.style.width = "auto";

                    var left = RCxPct * image.offsetWidth - parentW/2;

                    if(left < 0){

                        left = 0;

                    }

                    if(image.offsetWidth - left < parentW) {

                        left += (image.offsetWidth - left) - parentW;
                    }

                    image.style.top = "0px";
                    image.style.left = -left + "px";

                }
            
            }

            /* resizer.addEventListener('scroll', function(e) {
                
                console.log('firing');
                resize();
            });*/
      
        }
  };
}]);