
angular
.module('fonderieComponent')
.directive('twitch', [ 'MediaService', '$compile', function (MediaService, $compile) {

    return {
        restrict: 'E',

        scope : {
            data:"="

        },

        link: function ($scope, $element, $attr) {

            console.log($scope.data);

            var playerId = 'twitch'+Math.floor(Math.random()*10000000);

            var playerDom = document.createElement('div');
            playerDom.style.display ='inline';
            playerDom.id = playerId;

            $element.append(playerDom);

            $scope.initPlayer = function() {

                payload = {
                    width: '100%',
                    height: '100%',
                    // only needed if your site is also embedded on embed.example.com and othersite.example.com 
                    parent: ["fonderiedarling.local", "fonderiedarling.org"]

                }

                if($scope.data.channel !== undefined) {

                    payload.channel = $scope.data.channel;
                }

                if($scope.data.video !== undefined) {

                    payload.video = $scope.data.video;
                }


                var twitchPlayer = new Twitch.Embed(playerId, payload);

                console.log(twitchPlayer);

            }


            if(!window.Twitch) {
                $.getScript( "https://embed.twitch.tv/embed/v1.js", function( data, textStatus, jqxhr ) {

                    $scope.initPlayer();

                });


            } else {

                $scope.initPlayer();

            }

            


        }
    }
}]);

