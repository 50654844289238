var app = angular.module('fonderieComponent', ['pascalprecht.translate']);


app.config(function($sceDelegateProvider) {
  $sceDelegateProvider.resourceUrlWhitelist([
    "https://www.youtube.com/**"
  ]);

});


app.config(function ($translateProvider) {
  $translateProvider.translations('en', {

    "CART_TITLE":"Cart",
    "CART_EMPTY":"Your cart is empty.",
    "CHECKOUT_BUTTON":"Checkout",
    "ADD_TO_CART":"Add to cart",

    "SHIPPING":{
      "TITLE":"Choose your shipping method",
      "PICKUP_LABEL":"Pickup on site",
      "MAIL_DELIVERY_LABEL":"Canada Post"
    }


  });
  $translateProvider.translations('fr', {
    "CART_TITLE":"Panier",
    "CART_EMPTY":"Votre panier est vide.",
    "CHECKOUT_BUTTON":"Payer",
    "ADD_TO_CART":"Ajouter au panier",
   
    "SHIPPING":{
      "TITLE":"Sélectionnez votre méthode de livraison",
      "PICKUP_LABEL":"Récuperer sur place",
      "MAIL_DELIVERY_LABEL":"Poste Canada"
    }
    
  });
  $translateProvider.preferredLanguage(fdGlobals.lang);
});


angular.element(function() {

    angular.bootstrap(document.body, ['fonderieComponent']);
});