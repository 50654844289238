
angular
.module('fonderieComponent')
.directive('media', [ 'MediaService', '$compile', function (MediaService, $compile) {

    return {
        restrict: 'E',
        transclude : "true",

        scope : {
            data:"@"

        },

        link: function ($scope, $element, $attr) {



            var media = MediaService.getMedia($scope.data);

            $element[0].style.width = "100%";

            var mediaData = new media($scope.data);

            console.log(mediaData);

            var element = $(document.createElement(mediaData.component));
            $scope.mediaData = mediaData;

            element.attr('data', 'mediaData');
            $element.append(element);
            $compile($element.contents())($scope);





           


        }
    }

}]);

