angular.module('fonderieComponent').run(['$templateCache', function($templateCache) {
  'use strict';

  $templateCache.put('js/partials/add-product.html',
    "\n" +
    "\n" +
    "<div ng-click=\"addProduct()\" class=\"buy button\">\n" +
    "\n" +
    "    {{'ADD_TO_CART' | translate}}\n" +
    "\n" +
    "</div>\n"
  );


  $templateCache.put('js/partials/anchor-menu.html',
    "\n" +
    "\n" +
    "<ul>\n" +
    "\n" +
    "	<li ng-repeat=\"anchor in anchors\"><a href=\"{{baseurl}}#{{anchor.id}}\">{{anchor.id}}</a></li>\n" +
    "\n" +
    "</ul>\n" +
    "<div class=\"border-top\"></div>\n" +
    "\n" +
    "        "
  );


  $templateCache.put('js/partials/calendar-menu.html',
    "<div class=\"container\">\n" +
    "\n" +
    "	<div class=\"border-top\"></div>\n" +
    "\n" +
    "	<div class=\"container submenu\">\n" +
    "\n" +
    "		<span class=\"item\" ng-repeat='(yearKey, year) in years' ng-class=\"{ active : activeDate.year() == year.year() }\" ng-click='changeYear(year)'>\n" +
    "\n" +
    "			{{year | moment : 'YYYY'}}\n" +
    "\n" +
    "		</span>\n" +
    "\n" +
    "	</div>\n" +
    "\n" +
    "	<div class=\"border-top\"></div>\n" +
    "\n" +
    "	<div class=\"container submenu\">\n" +
    "\n" +
    "		<span class=\"item\" ng-repeat='(monthKey, month) in months' ng-class=\"{ active : activeDate.month() == month.month() }\" ng-click='changeMonth(month)'>\n" +
    "\n" +
    "	    	{{month | moment : 'MMMM'}}\n" +
    "		\n" +
    "		</span>\n" +
    "\n" +
    "	</div>\n" +
    "\n" +
    "	<div class=\"border-top\"></div>\n" +
    "\n" +
    "	<div class=\"container submenu\">\n" +
    "\n" +
    "		<span class=\"item\" ng-repeat='(dayKey, day) in days' ng-class=\"{ active :  startDate.format('DD') <= day.format('DD') && endDate.format('DD') >= day.format('DD') }\"  ng-click='changeDay(day)'>\n" +
    "\n" +
    "		    {{day | moment : 'DD'}}\n" +
    "\n" +
    "		</span>\n" +
    "\n" +
    "	</div>\n" +
    "\n" +
    "	<div class=\"border-top\"></div>\n" +
    "\n" +
    "	<div class=\"container center\">\n" +
    "\n" +
    "		<h1 style=\"padding-top:10px\">\n" +
    "			<span ng-click=\"getPreviousDay()\" class=\"icon icon-arrow-left-c\"></span>\n" +
    "\n" +
    "				{{startDate | moment : 'DD MMMM YYYY'}} / {{endDate | moment : 'DD MMMM YYYY'}}\n" +
    "			\n" +
    "			<span ng-click=\"getNextDay()\" class=\"icon icon-arrow-right-c\"></span>\n" +
    "		</h1>\n" +
    "\n" +
    "	</div>\n" +
    "</div>\n"
  );


  $templateCache.put('js/partials/cart.html',
    "\n" +
    "    <div class=\"cart-container\">\n" +
    "\n" +
    "        <div class=\"cart-row row p-4\">\n" +
    "\n" +
    "            <span ng-click=\"closeCart()\" class=\"col-12 icon-close pb-4\"></span>\n" +
    "            \n" +
    "            \n" +
    "            <div class=\"col-12 pt-4\">\n" +
    "\n" +
    "                <h1>{{'CART_TITLE'|translate}}</h1>\n" +
    "\n" +
    "            </div>\n" +
    "\n" +
    "\n" +
    "            <div class=\"col-12 mt-4 mb-4 cart-items\">\n" +
    "                <div class=\"seperator\">\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"col-12 cart-empty\" ng-show=\"cartIsEmpty()\">\n" +
    "                   - {{'CART_EMPTY' | translate}} -\n" +
    "                </div>\n" +
    "\n" +
    "                <div ng-repeat=\"(key, cartItem) in cartItems\" class=\"row cart-item align-items-center\">\n" +
    "                    <div class=\"col-3\">\n" +
    "                        <img class=\"img-fluid float-start\" ng-src=\"{{cartItem.images[0]}}\"/>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-9\">\n" +
    "\n" +
    "                        <div class=\"row\">\n" +
    "\n" +
    "                            <div class=\"col-12\">\n" +
    "\n" +
    "                                <a href=\"{{cartItem.url}}\">\n" +
    "\n" +
    "                                    <span class=\"cart-item-title\">\n" +
    "                                        {{cartItem.name}}\n" +
    "                                    </span>     \n" +
    "\n" +
    "                                </a>\n" +
    "\n" +
    "                                <span class=\"cart-item-description\">\n" +
    "                                    {{cartItem.description}}\n" +
    "                                </span>\n" +
    "                                 \n" +
    "                            </div>\n" +
    "\n" +
    "                            <div class=\"col-12 pt-2\">\n" +
    "\n" +
    "                                <span class=\"price\">\n" +
    "                                    {{getPrice(cartItem) | currency}}\n" +
    "                                </span>\n" +
    "\n" +
    "                            </div>\n" +
    "     \n" +
    "                            <div class=\"col-12 pt-2\">\n" +
    "\n" +
    "                                <div class=\"row\">\n" +
    "                                    <div class=\"col-6\">\n" +
    "\n" +
    "                                        <div class=\"row text-center\">\n" +
    "                                            <div ng-click=\"subtractItem(cartItem)\" class=\"col\">\n" +
    "                                                <i class=\"fa fa-minus\" aria-hidden=\"true\"></i>\n" +
    "                                            </div>\n" +
    "                                            <div class=\"col\">\n" +
    "                                                {{getQuantity(cartItem)}}\n" +
    "                                            </div>\n" +
    "                                            <div class=\"col\">\n" +
    "                                                <i ng-click=\"addItem(cartItem)\" class=\"fa fa-plus\" aria-hidden=\"true\"></i>\n" +
    "                                            </div>\n" +
    "                                        </div>\n" +
    "     \n" +
    "            \n" +
    "                                        \n" +
    "                                    </div>\n" +
    "    \n" +
    "                                    <div ng-click=\"removeItem(cartItem)\" class=\"col-6 text-right\">\n" +
    "                                        <i class=\"fas fa-trash-alt\"></i>\n" +
    "                                    </div>\n" +
    "                                </div>\n" +
    "\n" +
    "                            </div>\n" +
    "         \n" +
    "\n" +
    "     \n" +
    "                        </div>\n" +
    "\n" +
    "                            \n" +
    "                    </div>\n" +
    "\n" +
    "     \n" +
    "        \n" +
    "                </div>\n" +
    "        \n" +
    "\n" +
    "            </div>\n" +
    "\n" +
    "\n" +
    "            <div class=\"col-12\">\n" +
    "                <shipping></shipping>\n" +
    "            </div>\n" +
    "        \n" +
    "            <div class=\"col-12\">\n" +
    "\n" +
    "            \n" +
    "                <div class=\"total p-4 mt-2 mb-2\">\n" +
    "                    Total : {{getTotal() | currency}}\n" +
    "                </div>\n" +
    "            \n" +
    "        \n" +
    "            </div>\n" +
    "\n" +
    "\n" +
    "             <!-- <div class=\"col-12 shipping\">\n" +
    "\n" +
    "            \n" +
    "               <button ng-click=\"showShipping()\" type=\"button\" class=\"btn btn-primary btn-checkout\">Calculate Shipping <i class=\"fas fa-truck\"></i></button>\n" +
    "             \n" +
    "            </div>\n" +
    "              -->\n" +
    "\n" +
    "\n" +
    "            <div class=\"col-12 checkout\">\n" +
    "\n" +
    "            \n" +
    "                <button ng-class=\"{disabled : cartIsEmpty()}\" ng-click=\"checkout()\" type=\"button\" class=\"btn btn-primary btn-checkout\">{{'CHECKOUT_BUTTON'| translate}}</button>\n" +
    "            \n" +
    "        \n" +
    "            </div>\n" +
    "            \n" +
    "\n" +
    "        \n" +
    "        </div>\n" +
    "\n" +
    "    </div>\n" +
    "\n" +
    "\n"
  );


  $templateCache.put('js/partials/checkout.html',
    "\n" +
    "\n" +
    "<div clas=\"row\">\n" +
    "\n" +
    "    <div class=\"col\">\n" +
    "        <span id=\"card\">\n" +
    "\n" +
    "        </span>\n" +
    "    </div>\n" +
    "\n" +
    "</div>\n"
  );


  $templateCache.put('js/partials/donation.html',
    "\n" +
    "\n" +
    "\n" +
    "\n" +
    "	<input id=\"amount\" class=\"fieldInput\" type=\"number\" ng-model=\"amount\" name=\"amount\" >\n" +
    "		\n" +
    "\n" +
    "	<div ng-click=\"send()\" class=\"button\">\n" +
    "		\n" +
    "		{{buttontext}}\n" +
    "	\n" +
    "	</div>\n" +
    "\n" +
    "\n"
  );


  $templateCache.put('js/partials/facebook.html',
    ""
  );


  $templateCache.put('js/partials/product-editor.html',
    "<div class=\"row\">\n" +
    "\n" +
    "    <div class=\"col-lg-6\">\n" +
    "        <label class=\"x-form-item-label modx-tv-label\" for=\"stripePriceId\"> Stripe Price Id</label><br/>\n" +
    "        <input ng-model-options=\"{ debounce: 500 }\" ng-model=\"stripeData.stripePriceId\" id=\"stripePriceId\" name=\"stripePriceId\" type=\"text\" class=\"x-form-text x-form-field w-100\">    \n" +
    "    </div>\n" +
    "    <div class=\"col-lg-6\">\n" +
    "        <label class=\"x-form-item-label modx-tv-label\" for=\"stripeProductId\"> Stripe Product Id</label><br/>\n" +
    "        <input ng-model-options=\"{ debounce: 500 }\" ng-model=\"stripeData.id\" id=\"stripeProductId\" name=\"stripeProductId\" type=\"text\" class=\"x-form-text x-form-field w-100\">    \n" +
    "    </div>\n" +
    "    <div class=\"col-lg-6\">\n" +
    "        <div class=\"row\">\n" +
    "            <div class=\"col-lg-6\">\n" +
    "                <label class=\"x-form-item-label modx-tv-label\" for=\"price\"> Price </label><br/>\n" +
    "                <input ng-model-options=\"{ debounce: 500 }\" ng-model=\"stripeData.price\" id=\"price\" name=\"price\" type=\"text\" class=\"x-form-text x-form-field \">    \n" +
    "      \n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"col-lg-6\">\n" +
    "                <label class=\"x-form-item-label modx-tv-label\" for=\"availableQuantity\"> Available Quantity </label><br/>\n" +
    "                <input ng-model-options=\"{ debounce: 500 }\" ng-model=\"stripeData.metadata.availableQuantity\" id=\"availableQuantity\" name=\"availableQuantity\" type=\"number\" class=\"x-form-text x-form-field \">    \n" +
    "            </div>\n" +
    "\n" +
    "            <!--<div class=\"col-lg-6\">\n" +
    "                <label class=\"x-form-item-label modx-tv-label\" for=\"tyep\"> Product Type</label><br/>\n" +
    "                <input ng-model-options=\"{ debounce: 500 }\" ng-model=\"stripeData.type\" id=\"type\" name=\"type\" type=\"text\" class=\"x-form-text x-form-field \">    \n" +
    "            </div>-->\n" +
    "        </div>\n" +
    "\n" +
    "    </div>\n" +
    "    <div class=\"col-lg-6\">\n" +
    "        <label class=\"x-form-item-label modx-tv-label\" for=\"shippable\"> Shippable </label><br/>  \n" +
    "        <input ng-model-options=\"{ debounce: 500 }\" ng-model=\"stripeData.shippable\" id=\"shippable\" name=\"shippable\"  type=\"checkbox\" >\n" +
    "   \n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"col-lg-6\">\n" +
    "        <label class=\"x-form-item-label modx-tv-label\" for=\"url\"> Product url  </label><br/>  \n" +
    "        <input ng-model-options=\"{ debounce: 500 }\" ng-model=\"stripeData.url\" id=\"url\" name=\"url\" class=\"x-form-text x-form-field w-100\" type=\"text\" >\n" +
    "   \n" +
    "    </div>\n" +
    "    \n" +
    "\n" +
    "    <!-- <div class=\"col-lg-6\">\n" +
    "\n" +
    "        <h5>Shipping Rates</h5>\n" +
    "\n" +
    "        <div class=\"border m-2 p-2 row\" ng-repeat=\"(key, value) in stripeData.shipping_rates\">\n" +
    "            <div class=\"col-12 m-1\">\n" +
    "                <label class=\"x-form-item-label modx-tv-label\" for=\"url\"> Shipping name  </label><br/>  \n" +
    "                <input ng-model-options=\"{ debounce: 500 }\" ng-model=\"stripeData.shipping_rates[key].name\" class=\"x-form-text x-form-field w-100\" type=\"text\" >\n" +
    "\n" +
    "            </div>    \n" +
    "            <div class=\"col-12 m-1\">\n" +
    "                <label class=\"x-form-item-label modx-tv-label\" for=\"url\"> Shipping stripeId  </label><br/>  \n" +
    "                <input ng-model-options=\"{ debounce: 500 }\" ng-model=\"stripeData.shipping_rates[key].stripeId\" class=\"x-form-text x-form-field w-100\" type=\"text\" >\n" +
    "         \n" +
    "            </div>            \n" +
    "            \n" +
    "            <div class=\"col-12 m-1 \" ng-click=\"removeShippingRate(key)\">\n" +
    "                <button class=\"x-btn x-btn-small x-btn-icon-small-left x-btn-text bmenu x-btn-noicon\">\n" +
    "                    Remove shipping item\n" +
    "                </button>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div  ng-click=\"addShippingRate()\">\n" +
    "            <button class=\"x-btn x-btn-small x-btn-icon-small-left x-btn-text bmenu x-btn-noicon\">\n" +
    "                + Add shipping \n" +
    "           </button>\n" +
    "        </div>\n" +
    "    </div> -->\n" +
    "\n" +
    "    <div class=\"col-lg-6\">\n" +
    "        <h5>Stripe Actions</h5>\n" +
    "        <button ng-click=\"updateProduct()\" class=\"x-btn x-btn-small x-btn-icon-small-left x-btn-text bmenu x-btn-noicon\">Update Stripe Product</button>\n" +
    "        <button ng-click=\"parseProduct()\" class=\"x-btn x-btn-small x-btn-icon-small-left x-btn-text bmenu x-btn-noicon\">Parse Stripe Product</button>\n" +
    "\n" +
    "    </div>\n" +
    "\n" +
    "\n" +
    "    \n" +
    "    \n" +
    "</div>"
  );


  $templateCache.put('js/partials/shipping.html',
    "\n" +
    "<div class=\"shipping-container text-right py-4\">\n" +
    "\n" +
    "    <h4><i class=\"fas fa-truck\"></i> {{'SHIPPING.TITLE'|translate}} </h4>\n" +
    "    <div>\n" +
    "\n" +
    "        <div class=\"row\">\n" +
    "            <div ng-click=\"selectShippingMethod('mail')\" class=\"col-12\">\n" +
    "               \n" +
    "                <i ng-show=\"shippingMethod.key == 'mail'\" class=\"fa fa-check-circle\"></i>\n" +
    "                <i ng-show=\"shippingMethod.key != 'mail'\" class=\"fa fa-circle\"></i>\n" +
    "                <span>{{'SHIPPING.MAIL_DELIVERY_LABEL' | translate}}</span> \n" +
    "            </div>\n" +
    "            <div ng-click=\"selectShippingMethod('pickup')\" class=\"col-12\">\n" +
    "            \n" +
    "                <i ng-show=\"shippingMethod.key == 'pickup'\" class=\"fa fa-check-circle\"></i>\n" +
    "                <i ng-show=\"shippingMethod.key != 'pickup'\" class=\"fa fa-circle\"></i>\n" +
    "                <span>{{'SHIPPING.PICKUP_LABEL' | translate}}</span>  \n" +
    "            </div>\n" +
    "\n" +
    "        </div>\n" +
    "\n" +
    "\n" +
    "   \n" +
    "        \n" +
    "    </div>\n" +
    "\n" +
    "\n" +
    "</div>"
  );


  $templateCache.put('js/partials/youtube.html',
    "\n" +
    "<span ng-if=\"instantiated\" >\n" +
    "    <iframe class=\"youtube-media\" src=\"{{url}}\" frameborder=\"0\" allow=\"accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture\" allowfullscreen></iframe>\n" +
    "\n" +
    "</span>\n" +
    "        "
  );

}]);
