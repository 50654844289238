
angular
.module('fonderieComponent')
.directive('checkout', [ 'stripe', function (stripe) {

    return {
        restrict: 'E',
        templateUrl:"js/partials/checkout.html",
        scope : {


        },
        link: function ($scope, $element, $attr) {

          
            var elements = stripe.elements({
                fonts: [
                  {
                    cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
                  },
                ],
                // Stripe's examples are localized to specific languages, but if
                // you wish to have Elements automatically detect your user's locale,
                // use `locale: 'auto'` instead.
                locale: window.__exampleLocale
              });

              
              var card = elements.create('card', {
                iconStyle: 'solid',
                style: {
                  base: {
                    iconColor: '#c4f0ff',
                    color: '#fff',
                    fontWeight: 500,
                    fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                    fontSize: '16px',
                    fontSmoothing: 'antialiased',
            
                    ':-webkit-autofill': {
                      color: '#fce883',
                    },
                    '::placeholder': {
                      color: '#87BBFD',
                    },
                  },
                  invalid: {
                    iconColor: '#FFC7EE',
                    color: '#FFC7EE',
                  },
                },
              });

      
              card.mount($element.find("#card")[0]);

        }
    }

}]);