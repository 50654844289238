/**
 * Abstract Object defining a twitch item in the assets manager list.
 * @name MediaTwitchItem
 * @ngdoc service
 */
angular.module('fonderieComponent').factory('MediaTwitchItem', [ '$q', '$http', '$log', function ($q, $http, $log) {


	function MediaTwitchItem (payload) { 

        var self = this;
        
        self.component = 'twitch';

        if(typeof payload == 'string'){
            self.url = payload;
        }

		self.type = 'video';
        self.media_source = "twich";

        self.video = MediaTwitchItem.parseVideo(payload);

        if(self.video == undefined) {
            self.channel = MediaTwitchItem.parseChannel(payload);
        }



        if(typeof payload == 'object') {

            $.extend(self, payload);

        }

	}


    MediaTwitchItem.type = MediaTwitchItem.prototype.type = 'video';


	/**
	 * Static function to validate a payload against this type.
	 * @param {Object} payload The payload of the asset to validate against.
	 * @return {Boolean} Returns a boolean whether the payload matches cirteria or not.
	 */
	MediaTwitchItem.validate = function (payload) {

        if(typeof payload === 'string') {
  
            return /twitch/i.test(payload);

        }

	}

    MediaTwitchItem.load = function(payload) {

    }


    /**
     * Parses the video id out of a url.
     * @param  {String} url The url of the media.
     * @return {String}     The id of the video. 
     */
    MediaTwitchItem.parseVideo = function (url) {

        try {

            var idObje = /[^videos/]+$/g.exec(url);
            return idObje[0];

        } catch(err){

        }

    }

    MediaTwitchItem.parseChannel = function (url) {

        //var urlObj = new URL(url);
        try {

            var idObj = /[^/]+$/g.exec(url);
            return idObj[0];

        }catch(err){

        }


        
    }
    MediaTwitchItem.getRatio = function(id) {


    }


    MediaTwitchItem.prototype.parseId = MediaTwitchItem.parseId; 

    MediaTwitchItem.prototype.load = MediaTwitchItem.load;

	return MediaTwitchItem;

}]);