
angular
.module('fonderieComponent')
.directive('addProduct', [ 'stripe', 'storeService', function (stripe, Store) {

    return {
        restrict: 'E',
        templateUrl:"js/partials/add-product.html",
        scope : {

            stripePriceId:'@',
            shippable:'@',
            

        },
        link: function ($scope, $element, $attr) {

            $scope.quantity = 1;

            $scope.addQuantity = function (){
                $scope.quantity+=1;
                
            }

            $scope.subtractQuantity = function (){
                $scope.quantity-=1;
            }

            $scope.addProduct = function(event){

                var data = { 'stripePriceId':$scope.stripePriceId, 'quantity':1 };

                if($scope.shippable) {
                    data.shippable = true;
                } else {
                    data.shippable = false;
                }

                Store.addItemByPriceId(data);
                Store.showCart();
            
            };

        }
    }

}]);