

angular
.module('fonderieComponent')
.service('stripe', function(){

    if(!window.fdGlobals && !window.fdGlobals.stripePublicKey) {
        throw 'fdGlobals.stripePublicKey missing. ';
    } 

    console.log('loading stripe');

    return Stripe(window.fdGlobals.stripePublicKey);
});