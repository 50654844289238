
angular
   .module('fonderieComponent').run( 
    [ 'MediaYoutubeItem', 'MediaTwitchItem', 'MediaFacebookItem', 'MediaService',

    function (
              MediaYoutubeItem,
              MediaTwitchItem,
              MediaFacebookItem,
              MediaService) {

      // Prepping the MediaService
        
        
      MediaService.addMedia('youtube', MediaYoutubeItem);

      MediaService.addMedia('twitch', MediaTwitchItem);
      MediaService.addMedia('facebook', MediaFacebookItem);


}]);

