angular.module('fonderieComponent').service('MediaService', ['$q', '$log', function($q, $log) {

    var self = this;

    this.types = []

    this.mediaPlayerComponents = [];

    self.addMedia = function (type, item) {


        if(type == undefined) {

            $log.warn('Type validation error : ' + type + ' has not been provided.');
        }

        if(item == undefined) {

            $log.warn('Type validation error : ' + item + ' has not been provided.');
        }

        //We first validate if the validate function has been provided by the system.
        if(typeof item.validate !== 'function') {

            $log.error(item + ' has no validate function exposed');

        }

        self.types.push({
            'type' : type,
            'item' : item
        });

    };

    self.getMedia = function (payload) {

        for(var i in self.types) {

            //We first validate if the validate function has been provided by the system.
            if(typeof self.types[i].item.validate !== 'function') {

                $log.error(self.types[i].type + ' has no validate function exposed.');
                continue;

            }

            //Then we check if validation passes.
            if(self.types[i].item.validate(payload)){

                return self.types[i].item;
            }

        }


    }

    self.addMediaPlayerComponent = function (component, item) {

        if(component == undefined) {

            $log.warn('Type validation error : type has not been provided.');
        }

        if(item == undefined) {

            $log.warn('Type validation error : item has not been provided.');
        }

        //We first validate if the validate function has been provided by the system.
        if(typeof item.validate !== 'function') {

            $log.error(item + ' has no validate function exposed');

        }

        this.mediaPlayerComponents.push({
            'component' : component,
            'item' : item
        });

    }

    /**
     * Provide the right component to play the media by validating the payload of the object
     */
    this.getMediaPlayerComponent = function (data) {

        for(var i in self.mediaPlayerComponents) {

            //We first validate if the validate function has been provided by the system.
            if(typeof self.mediaPlayerComponents[i].item.validate !== 'function') {

                $log.error(self.mediaPlayerComponents[i].type + ' has no validate function exposed');
                continue;

            }

            //Then we check if validation passes.
            if(self.mediaPlayerComponents[i].item.validate(data)){

                return self.mediaPlayerComponents[i].component;
            }

        }
    }


}]);