
angular
.module('fonderieComponent')
.directive('facebookVideo', [ 'MediaService', '$compile', 'resizer', function (MediaService, $compile, resizer) {

    return {
        restrict: 'E',
        transclude : "true",
        templateUrl:"js/partials/facebook.html",

        scope : {
            data:"="

        },

        link: function ($scope, $element, $attr) {


            var playerDom = document.createElement('div');

            playerDom.classList.add('fb-video');
            playerDom.setAttribute('data-href',$scope.data.url);
            playerDom.setAttribute('data-width', 'auto');
            //playerDom.setAttribute('data-lazy', 'true');

            playerDom.style.display = "block";
            //playerDom.style.position = "absolute";
            playerDom.style.zIndex = "inherit";



            $element.append(playerDom);

            $element[0].style.display = "block";
            $element[0].style.position = "absolute";


            $element[0].style.height = "100%";
            $element[0].style.width = "100%";


            var resize = function(callback) {

    

                var contenantHeight = playerDom.offsetHeight;
                var containerHeight = $element[0].offsetHeight;



                playerDom.style.top =  (containerHeight-contenantHeight)/2 +"px";

        

            }


            resizer.addEventListener('resize', function (callback) {


                resize(callback);

                setTimeout(function() {
                    resize();
                }, 200);

            }, $element);


            setInterval(function() {

                resize();
            }, 1000)




            if(window.FB == undefined){


                $.getScript( "https://connect.facebook.net/en_US/sdk.js", function( data, textStatus, jqxhr ) {


                });


                var facebookRoot = document.createElement('div');

                facebookRoot.setAttribute("id","fb-root");
                
                document.body.appendChild(facebookRoot);




                window.fbAsyncInit = function() {

                    FB.init({
                    appId      : '3288215797943636',
                    xfbml      : true,
                    version    : 'v3.2'
                    });

                    // Get Embedded Video Player API Instance
                    var my_video_player;


                    resize();




                    FB.Event.subscribe('xfbml.ready', function(msg) {
            
                        resize();


                        if (msg.type === 'video') {
                          my_video_player = msg.instance;
                        }
                    });
                };     

            }
        

            
        }
    }
}]);

