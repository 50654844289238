/**
 * Abstract Object defining a twitch item in the assets manager list.
 * @memberof SpotfulAssetManager 
 * @name MediaFacebookItem
 * @ngdoc service
 */
angular.module('fonderieComponent').factory('MediaFacebookItem', [ '$q', '$http', '$log', function ($q, $http, $log) {


	function MediaFacebookItem (payload) { 

        var self = this;
        
        self.component = 'facebook-video';

        if(typeof payload == 'string'){
            self.url = payload;
        }

		self.type = 'video';
        self.media_source = "facebook";

        self.id = MediaFacebookItem.parseVideo(self.url);



        if(typeof payload == 'object') {

            $.extend(self, payload);

        }

	}


    MediaFacebookItem.type = MediaFacebookItem.prototype.type = 'video';


	/**
     *
     * https://www.facebook.com/watch/?v=737225330446823 * 
	 * Static function to validate a payload against this type.
	 * @param {Object} payload The payload of the asset to validate against.
	 * @return {Boolean} Returns a boolean whether the payload matches cirteria or not.
	 */
	MediaFacebookItem.validate = function (payload) {

        console.log(payload);

        if(typeof payload === 'string') {
  
            return /www.facebook.com\//i.test(payload);

        }
        

	}

    MediaFacebookItem.load = function(payload) {

    }


    /**
     * Parses the video id out of a url.
     * @param  {String} url The url of the media.
     * @return {String}     The id of the video. 
     */
    MediaFacebookItem.parseVideo = function (url) {

        var idObje = /[^\/?v=]+$/g.exec(url);

        return idObje[0];

    }


    MediaFacebookItem.getRatio = function(id) {


    }


    MediaFacebookItem.prototype.parseId = MediaFacebookItem.parseId; 

    MediaFacebookItem.prototype.load = MediaFacebookItem.load;

	return MediaFacebookItem;

}]);