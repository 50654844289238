
angular
.module('fonderieComponent')
.directive('youtube', [ 'MediaService', '$compile', function (MediaService, $compile) {

    return {
        restrict: 'E',
        transclude : "true",
        templateUrl:"js/partials/youtube.html",

        scope : {
            data:"="

        },

        link: function ($scope, $element, $attr) {

            $scope.url;
            $scope.instantiated = false;

            $element[0].style.width = "100%";

            $scope.$watch('data', function(data) {


                if(data != undefined && data.id != undefined) {
                    $scope.instantiated = true;
                    $scope.url = 'https://www.youtube.com/embed/'+data.id;
                   // $scope.$digest();
                }

            });

        }
    }
}]);

